import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://3812f9aeb5df103a544eb1656f7535a6@o4507119679111168.ingest.de.sentry.io/4507904604307536",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.3,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()